import { Component, Inject, inject, OnInit } from "@angular/core";
import {
    AsyncPipe,
    DatePipe,
    NgOptimizedImage,
    NgTemplateOutlet,
} from "@angular/common";
import { Observable } from "rxjs";
import { UserDm } from "@/_models";
import UserModel = UserDm.UserModel;
import { USER_IS_AT_LEAST_ADMIN, UserService } from "@/_services";
import { SharedComponentsModule } from "@/components/shared/shared-components.module";
import { Router, RouterOutlet } from "@angular/router";
import { HomeCrewPerformanceComponent } from "@/components/home/home-crew-performance/home-crew-performance.component";
import { DropdownModule } from "primeng/dropdown";
import { FormsModule } from "@angular/forms";
import { AddonFeature } from "@/_models/addon-features";
import { filter, switchMap, tap } from "rxjs/operators";
import { AccountService } from "@/_services/account.service";
import { IssueService } from "@/_services/issue.service";
import { PromotionService } from "@/_services/promotion.service";
import { Issue } from "@/_models/issue.interface";
import { Promotion } from "@/_models/promotion.interface";
import { OverlayPanelModule } from "primeng/overlaypanel";

@Component({
    selector: "app-home",
    standalone: true,
    imports: [
        AsyncPipe,
        DatePipe,
        NgOptimizedImage,
        SharedComponentsModule,
        RouterOutlet,
        HomeCrewPerformanceComponent,
        DropdownModule,
        FormsModule,
        OverlayPanelModule,
        NgTemplateOutlet,
    ],
    templateUrl: "./home.component.html",
    styleUrl: "./home.component.scss",
})
export class HomeComponent implements OnInit {
    private userService = inject(UserService);
    private router = inject(Router);
    private accountService = inject(AccountService);
    private issueService = inject(IssueService);
    private promoService = inject(PromotionService);

    currentDate: Date;
    currentUser$: Observable<UserModel>;

    currentQuote: string = "";
    quoteList: string[] = [
        "Safety isn't a rulebook, it's a mindset.",
        "Safety first is safety always.",
        "The safest risk is the one you've planned for.",
        "You can't manage what you don't measure.",
        "The best safety tool is a well-trained mind.",
        "Awareness is the first step toward accident prevention.",
        "Risk management is the art of staying in control.",
        "A culture of safety starts with leadership.",
        "Good safety habits are built one decision at a time.",
        "Consistent safety practices prevent costly mistakes.",
        "Plan ahead—accidents don't give warnings.",
        "Better a thousand safety checks than one fatal mistake.",
        "Predict the risk, prevent the loss.",
        "Protect what matters—your life, your team, your future.",
        "Near misses are warnings—heed them.",
        "Complacency is the enemy of safety.",
        "Safety is not just a priority—it's a core value.",
        "Every risk ignored is a hazard waiting to happen.",
        "Vigilance today prevents disasters tomorrow.",
        "The best defense against risk is preparation.",
    ];

    quickActionList = [
        { label: "File Report", onClick: () => this.fileReport() },
        {
            label: "Start Corrective Action",
            onClick: () => this.startCorrectiveAction(),
        },
        { label: "Send Promotion", onClick: () => this.sendPromotion() },
        {
            label: "Manage Safety Objectives",
            onClick: () => this.manageSafetyObjectives(),
        },
        {
            label: "Manage Crew Members",
            onClick: () => this.manageCrewMembers(),
        },
    ];

    navTabList = [
        { label: "Tasks", link: "tasks" },
        /*{ label: "Mentions", link: "mentions" },*/
        { label: "Promotions", link: "promotions" },
    ];

    constructor(
        @Inject(USER_IS_AT_LEAST_ADMIN) public isAdmin$: Observable<boolean>,
    ) {}

    ngOnInit(): void {
        this.currentDate = new Date();
        this.currentUser$ = this.userService.getCurrentUserAsync();
        this.currentQuote =
            this.quoteList[Math.floor(Math.random() * this.quoteList.length)];
    }

    submitAssessment() {
        this.router.navigateByUrl("/tasks/createFlight?formFilter=assessments");
    }

    fileReport() {
        this.accountService
            .checkFormFeature(AddonFeature.SAFETY_ASSURANCE)
            .pipe(
                filter((result) => result),
                tap(() =>
                    this.router.navigateByUrl(
                        "/tasks/createFlight?formFilter=not-assessments",
                    ),
                ),
            )
            .subscribe();
    }

    startCorrectiveAction() {
        this.accountService
            .checkFormFeature(AddonFeature.SAFETY_ASSURANCE)
            .pipe(
                filter((result) => result),
                switchMap(() => this.issueService.createIssue()),
                tap((issue: Issue) =>
                    this.router.navigateByUrl(`/mitigations/${issue._id}`),
                ),
            )
            .subscribe();
    }

    sendPromotion() {
        this.accountService
            .checkFormFeature(AddonFeature.SAFETY_PROMOTION)
            .pipe(
                filter((result) => result),
                switchMap(() => this.promoService.createPromotion()),
                tap((promotion: Promotion) =>
                    this.router.navigateByUrl(`/promotions/${promotion._id}`),
                ),
            )
            .subscribe();
    }

    manageSafetyObjectives() {
        this.accountService
            .checkFormFeature(AddonFeature.SAFETY_POLICY)
            .pipe(
                filter((result) => result),
                tap(() => this.router.navigateByUrl("/policies/objectives")),
            )
            .subscribe();
    }

    manageCrewMembers() {
        this.router.navigateByUrl("/profile/users");
    }

    q(event) {
        console.log(event);
    }
}
