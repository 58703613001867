@if (!(promoList$ | async)) {
    <app-loading-indicator></app-loading-indicator>
} @else {
    @if ((promoList$ | async)?.length === 0) {
        <app-card [noMargin]="true" [showHeader]="false">
            <div card-body class="table-empty-state h-auto mb-5">
                <img id="img-empty-promotions" class="empty" alt="Empty" src="/assets/img/empty-promotions.svg" />
                <h2>No Safety Messages</h2>
                <p>
                    You're all clear! No new safety updates
                    <br />
                    have landed in your inbox.
                </p>
            </div>
        </app-card>
    } @else {
        <div class="list-wrapper">
            @for (promo of promoList$ | async; track promo; let index = $index) {
                <app-home-promotion-card
                    [promo]="promo"
                    [isLast]="index + 1 === (promoList$ | async)?.length">
                </app-home-promotion-card>
            }
        </div>

        @if (total >= pageSize) {
            <p-paginator
                (onPageChange)="onPageChange($event)"
                [first]="1"
                [rows]="10"
                [totalRecords]="total"
                [showCurrentPageReport]="false"
                [showPageLinks]="false"
                [showJumpToPageDropdown]="true">
            </p-paginator>
        }
    }
}
