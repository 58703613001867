import { Component, inject, OnInit } from "@angular/core";
import { map, Observable, tap } from "rxjs";
import {
    AssessmentService,
    USER_IS_AT_LEAST_ADMIN,
    UserService,
} from "@/_services";
import { AssessmentDto, UserDm } from "@/_models";
import UserModel = UserDm.UserModel;
import { PromotionService } from "@/_services/promotion.service";
import { Router } from "@angular/router";
import { Promotion } from "@/_models/promotion.interface";
import { IssueService } from "@/_services/issue.service";
import { Issue } from "@/_models/issue.interface";

@Component({
    selector: "app-welcome-trial",
    templateUrl: "./welcome-trial.component.html",
    styleUrl: "./welcome-trial.component.scss",
})
export class WelcomeTrialComponent implements OnInit {
    private userService = inject(UserService);
    private formService = inject(AssessmentService);
    private issueService = inject(IssueService);
    private promotionService = inject(PromotionService);
    private router = inject(Router);

    currentDate: Date;
    currentUser$: Observable<UserModel>;
    isAtLeastAdmin$: Observable<boolean> = inject(USER_IS_AT_LEAST_ADMIN);

    setupActionList: any[] = [
        {
            icon: "/assets/img/welcome-trial/setup-aircraft.svg",
            title: "Add Aircraft",
            description:
                "Enter your aircraft type and identifiers to use in risk assessments.",
            link: "/profile/aircraft",
        },
        {
            icon: "/assets/img/welcome-trial/setup-members.svg",
            title: "Add Crew Members",
            description: "Invite colleagues to join your safety journey.",
            link: "/profile/users",
        },
        {
            icon: "/assets/img/welcome-trial/setup-integrations.svg",
            title: "Integrate Scheduling",
            description:
                "Generate risk assessments and share risk data automatically.",
            link: "/profile/integrations",
        },
        {
            icon: "/assets/img/welcome-trial/setup-manuals.svg",
            title: "Upload Your Manuals",
            description:
                "Explore and add different form starter templates to your account.",
            link: "/policies/manuals",
        },
        {
            icon: "/assets/img/welcome-trial/setup-templates.svg",
            title: "Explore Form Templates",
            description: "Clone and customize various starter form templates.",
            link: "/assessments/new",
        },
    ];

    ngOnInit(): void {
        this.currentDate = new Date();
        this.currentUser$ = this.userService.getCurrentUserAsync();
    }

    takeAssessment() {
        this.formService
            .listAssessments()
            .pipe(
                map(
                    (assessments) =>
                        assessments.find((asmt) => asmt.isDefault) ||
                        assessments[0],
                ),
                tap((redirectAssessment: AssessmentDto) =>
                    this.router.navigate(["tasks", "createFlight"], {
                        queryParams: { assessmentId: redirectAssessment._id },
                    }),
                ),
            )
            .subscribe();
    }

    submitHazard() {
        this.router.navigateByUrl("/tasks/createFlight");
    }

    mitigateRisk() {
        this.issueService
            .createIssue()
            .pipe(
                tap((issue: Issue) =>
                    this.router.navigateByUrl(
                        `/assurance/registry/${issue._id}`,
                    ),
                ),
            )
            .subscribe();
    }

    sendPromotion() {
        this.promotionService
            .createPromotion()
            .pipe(
                tap((promotion: Promotion) =>
                    this.router.navigateByUrl(`/promotions/${promotion._id}`),
                ),
            )
            .subscribe();
    }

    requestManual() {
        window.open(
            "https://preflightmitigator.com/manual-request.html",
            "_blank",
        );
    }

    integrateScheduling() {
        this.router.navigateByUrl("/profile/integrations");
    }

    modifyRiskForm() {
        this.formService
            .listAssessments()
            .pipe(
                map(
                    (assessments) =>
                        assessments.find((asmt) => asmt.isDefault) ||
                        assessments[0],
                ),
                tap((redirectAssessment: AssessmentDto) =>
                    this.router.navigateByUrl(
                        `/assessments/${redirectAssessment._id}/edit/questionnaire`,
                    ),
                ),
            )
            .subscribe();
    }
}
